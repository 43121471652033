import React, { useEffect, useState } from 'react';
import { parse } from 'query-string';

import Breadcrumbs from '../components/Breadcrumbs';
import Layout from '../components/Layout/Layout';
import Container from '../components/Container/Container';
import ProductCardGrid from '../components/ProductCardGrid';

import * as styles from './search.module.css';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Spacer } from '../uiBook';

const SearchPage = (props) => {
  const [data, setData] = useState([]);
  const params = parse(props.location.search);
  const searchQuery = params.q ? params.q : '';

  const productData = useStaticQuery(graphql`
    query {
      allGoogleProductsSheet {
        edges {
          node {
            slug
            title
            price
            images
            category
            sku
            weight
          }
        }
      }
    }
  `);

  useEffect(() => {
    const array = productData?.allGoogleProductsSheet?.edges?.map(
      (product) => product.node
    );
    const query = searchQuery;
    setData(
      array.filter(
        (product) =>
          product.title.includes(query) || product.category.includes(query)
      )
    );
  }, [productData, searchQuery]);

  const Results = () => (
    <>
      <Breadcrumbs
        crumbs={[
          { link: '/', label: 'Home' },
          { label: `Search results for '${searchQuery}'` },
        ]}
      />
      <div className={styles.searchLabels}>
        <h4>Search results for '{searchQuery}'</h4>
        <span>{data?.length} results</span>
      </div>
      <ProductCardGrid
        showSlider={false}
        height={580}
        columns={3}
        data={data}
      />
    </>
  );

  return (
    <Layout>
      <div className={styles.root}>
        <Container size={'large'} spacing={'min'}>
          <div
            style={{
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%',
            }}
          >
            <h1>Search</h1>
            <Spacer height={20} />
            <input
              className={styles.searchInput}
              type="text"
              placeholder="What are you looking for?"
              value={searchQuery}
              onChange={(e) => navigate(`/search?q=${e.target.value}`)}
            />
          </div>
          {searchQuery && <Results />}
        </Container>
      </div>
    </Layout>
  );
};

export default SearchPage;
